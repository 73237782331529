<template>
    <div>
        <v-card>
            <Snackbar
                v-if="snackbar"
                :mostrarSnackbar="snackbar"
                :corSnackbar="colorSnackbar"
                :mensagemSnackbar="mensagemSnackbar"
                @fecharSnackbar="snackbar = false"
            />
            
            <v-container>
                <v-form ref="form" v-model="valid">
                    <v-card-title
                        class="
                                    grey--text darken-2
                                    font-weight-black
                                    justify-center
                                    title-2
                                "
                        ><v-icon class="primary--text ma-1 pa-1">
                            keyboard_arrow_down
                        </v-icon>
                        Informe um nome para sua campanha</v-card-title
                    >
                    <v-card class="ma-3 pa-3" color="grey lighten-3">
                        <v-row class="d-flex justify-center">
                            <v-col lg="11" class="align-self-center">
                                <v-text-field
                                    solo
                                    class="mt-5"
                                    :rules="requiredRules"
                                    required
                                    :readonly="!newCampaign"
                                    v-model="campanha.campaign_name"
                                    prepend-inner-icon="mdi-format-title"
                                    placeholder="Nome da Campanha"
                                    hint="O nome é apenas de uso interno da plataforma"
                                />
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card-title
                        class="
                                    grey--text darken-2
                                    font-weight-black
                                    justify-center
                                    title-2
                                "
                        ><v-icon class="primary--text ma-1 pa-1">
                            keyboard_arrow_down
                        </v-icon>
                        Dados de exibição na mensagem</v-card-title
                    >
                    <v-card class="ma-3 pa-3" color="grey lighten-3">
                        <v-row class="d-flex justify-center">
                            <v-col lg="6" class="align-self-center">
                                <v-text-field
                                    solo
                                    class="mt-5"
                                    :rules="requiredRules"
                                    required
                                    :readonly="!newCampaign"
                                    v-model="campanha.destination_name"
                                    prepend-inner-icon="mdi-domain"
                                    placeholder="Nome de exibição do Apresentante"
                                    hint="Esse nome irá ser exibido em todas as mensagens"
                                />
                            </v-col>
                            <v-col lg="5" class="align-self-center">
                                <v-text-field
                                    solo
                                    class="mt-5"
                                    :rules="requiredRules"
                                    required
                                    :readonly="!newCampaign"
                                    prepend-inner-icon="mdi-link"
                                    v-model="campanha.destination_url"
                                    placeholder="Link de contato"
                                    hint="O link de contato será exibido em todas as mensagens"
                                />
                            </v-col>
                            <v-col lg="11" class="align-self-center">
                                <v-text-field
                                    solo
                                    class="mt-5"
                                    :rules="requiredRules"
                                    required
                                    :readonly="!newCampaign"
                                    prepend-inner-icon="mdi-domain"
                                    v-model="campanha.email_subject"
                                    placeholder="Assunto do email"
                                />
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card-title
                        v-if="newCampaign"
                        class="
                                    grey--text darken-2
                                    font-weight-black
                                    justify-center
                                    title-2
                                "
                        ><v-icon class="primary--text ma-1 pa-1">
                            keyboard_arrow_down
                        </v-icon>
                        Selecione um template para a campanha</v-card-title
                    >

                    <v-card
                        class="ma-3 pa-3 d-flex justify-center"
                        color="grey lighten-3"
                        style="border-radius:20px"
                        v-if="templateLoading && newCampaign"
                    >
                        <v-card-title class="ma-3 pa-3 secondary--text">
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                class="mx-3 px-3"
                            ></v-progress-circular
                            >Buscando templates
                        </v-card-title>
                    </v-card>
                    <v-card
                        class="ma-3 pa-3"
                        color="grey lighten-3"
                        style="border-radius:20px"
                        v-if="!templateLoading && newCampaign"
                    >
                        <v-row>
                            <v-row class="d-flex justify-center">
                                <v-col
                                    cols="10"
                                    sm="8"
                                    lg="3"
                                    class="ma-2 pa-3"
                                >
                                    <v-card-title>
                                        Selecione um template:
                                    </v-card-title>
                                    <v-select
                                        prepend-inner-icon="mdi-message-processing"
                                        v-model="campanhaSelecionada"
                                        solo
                                        :items="status"
                                        label="Selecione um Template"
                                        item-value="id"
                                        item-text="name"
                                        :return-object="true"
                                        @input="atualizaInput"
                                    ></v-select>
                                    <v-card-text>
                                        Os
                                        <strong>templates de mensagens</strong>
                                        são préviamente aprovados através da
                                        ferramenta de envio. Por esse motivo
                                        todos os templates que são utilizados
                                        para mensagens transacionais na Recuperi
                                        precisam ser préviamente verificados e
                                        aprovados por nossa equipe. Caso esteja
                                        buscando um template que não consta
                                        nessa lista, por favor entre em contato.
                                    </v-card-text>
                                </v-col>
                                <v-col lg="8">
                                    <div
                                        v-if="!campanhaSelecionada"
                                        style="border-radius: 20px;"
                                    >
                                        <v-card
                                            class="ma-3 pa-3 d-flex justify-center align-center"
                                            height="400px"
                                        >
                                            <v-icon class="mx-3 px-3"
                                                >mdi-information-outline</v-icon
                                            >Nenhum template selecionado.
                                        </v-card>
                                    </div>
                                    <div
                                        v-if="campanhaSelecionada"
                                        style="border-radius: 20px;"
                                    >
                                        <v-card>
                                            <span v-html="mensagemExibicao">
                                            </span>
                                        </v-card>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-row>
                    </v-card>
                    <v-card-title
                        v-if="newCampaign"
                        class="
                                    grey--text darken-2
                                    font-weight-black
                                    justify-center
                                    title-2
                                "
                        ><v-icon class="primary--text ma-1 pa-1">
                            keyboard_arrow_down
                        </v-icon>
                        Seu template está pronto? Ative no botão
                        abaixo</v-card-title
                    >
                    <v-card class="ma-3 pa-3" v-if="newCampaign">
                        <v-row
                            style="display: flex; align-items: center; justify-content: center;"
                        >
                            <v-col cols="12" sm="12" md="3">
                                <v-card-text
                                    class="
                                                primary--text
                                                display-1
                                                font-weight-black
                                            "
                                >
                                    Ativo/Inativo
                                </v-card-text>
                            </v-col>
                            <v-col cols="1" sm="12" md="1">
                                <v-switch
                                    v-model="campanha.is_active"
                                    color="success"
                                    inset
                                    class="ml-5"
                                    not-required
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                                <v-card-text class="">
                                    Ao ativar, estará permitindo o envio da
                                    mensagem após salvar essa página.
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card
                        v-if="!newCampaign && campanha.is_active"
                        class="ma-3 pa-3"
                    >
                        <v-row
                            style="display: flex; align-items: center; justify-content: center;"
                        >
                            <v-col cols="1" sm="12" md="6">
                                <v-card-text
                                    class="
                                                primary--text
                                                display-1
                                                font-weight-black
                                                d-flex
                                                justify-center
                                            "
                                >
                                    Essa campanha está ativa:
                                </v-card-text>
                            </v-col>
                            <v-col
                                cols="1"
                                sm="6"
                                md="6"
                                class="d-flex
                                                justify-center"
                            >
                                <v-btn
                                    v-model="desabilita"
                                    @click="desabilitarCampanha"
                                    color="error"
                                    >desabilitar campanha</v-btn
                                >
                            </v-col>
                            <v-col cols="1" sm="12" md="12">
                                <v-card-text class="">
                                    Ao desabilitar, estará encerrando os envios
                                    recentes. Possívelmente, caso a campanha já
                                    estiver em processamento não será possível
                                    encerrar todos os envios.
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card
                        class="ma-3 pa-3"
                        v-if="!newCampaign && !campanha.is_active"
                    >
                        <v-row
                            style="display: flex; align-items: center; justify-content: center;"
                        >
                            <v-col cols="1" sm="12" md="6">
                                <v-card-text
                                    class="
                                                primary--text
                                                display-1
                                                font-weight-black
                                                d-flex
                                                justify-center
                                            "
                                >
                                    Essa campanha está inativa:
                                </v-card-text>
                            </v-col>
                            <v-col
                                cols="1"
                                sm="6"
                                md="6"
                                class="d-flex
                                                justify-center"
                            >
                                <v-btn disabled color="success"
                                    >habilitar campanha</v-btn
                                >
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card-title
                        v-if="!newCampaign"
                        class="
                                    grey--text darken-2
                                    font-weight-black
                                    justify-center
                                    title-2
                                "
                        ><v-icon class="primary--text ma-1 pa-1">
                            keyboard_arrow_down
                        </v-icon>
                        Confira abaixo os Títulos Vinculados a Campanha
                        <v-btn class="ma-3 pa-3" x-small fab @click="atualiza"
                            ><v-icon>sync</v-icon></v-btn
                        ></v-card-title
                    >
                    <v-card v-if="!newCampaign" class="ma-3 pa-3">
                        <v-card-title
                            v-if="loadingContatos"
                            class="ma-3 pa-3 secondary--text"
                        >
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                class="mx-3 px-3"
                            ></v-progress-circular
                            >Buscando contatos
                        </v-card-title>
                        <v-data-table
                            :headers="headersResults"
                            :items="titulosResult"
                            :loading="loadingContatos"
                            :items-per-page="5"
                            class="elevation-1"
                        >
                            <template
                                v-slot:[`item.created_at`]="{
                                    item
                                }"
                            >
                                <span>{{ formatDate(item.created_at) }}</span>
                            </template>
                            <template
                                v-slot:[`item.updated_at`]="{
                                    item
                                }"
                            >
                                <span>{{ formatDate(item.updated_at) }}</span>
                            </template>
                            <template v-slot:[`item.sent`]="{ item }">
                                <v-switch
                                    :color="item.sent == true ? 'green' : 'red'"
                                    readonly
                                    v-model="item.sent"
                                    inset
                                    :style="{
                                        color:
                                            item.sent == true ? 'green' : 'red'
                                    }"
                                >
                                </v-switch>
                            </template>
                            <template v-slot:[`item.bounce`]="{ item }">
                                <v-switch
                                    :color="
                                        item.bounce == true ? 'green' : 'red'
                                    "
                                    readonly
                                    v-model="item.bounce"
                                    inset
                                    :style="{
                                        color:
                                            item.bounce == true
                                                ? 'green'
                                                : 'red'
                                    }"
                                >
                                </v-switch>
                            </template>
                        </v-data-table>
                    </v-card>
                    <v-card-title
                        v-if="newCampaign"
                        class="
                                    grey--text darken-2
                                    font-weight-black
                                    justify-center
                                    title-2
                                "
                        ><v-icon class="primary--text ma-1 pa-1">
                            keyboard_arrow_down
                        </v-icon>
                        Vamos Selecionar os títulos para a
                        campanha:</v-card-title
                    >
                    <v-card v-if="newCampaign" class="ma-3 pa-3">
                        <v-row
                            style="display: flex; align-items: center; justify-content: center;"
                        >
                            <v-col cols="1" sm="12" md="12">
                                <v-card
                                    @keyup.native.enter="botaoFiltrar"
                                    class="my-2"
                                >
                                    <v-row
                                        align="center"
                                        class="mx-3 mt-3 mb-0"
                                    >
                                        <v-col
                                            cols="5"
                                            xs="2"
                                            sm="2"
                                            md="3"
                                            lg="3"
                                            class="d-flex flex-row"
                                        >
                                            <v-textarea
                                                auto-grow
                                                solo
                                                small
                                                v-bind:style="{
                                                    maxHeight: '15rem',
                                                    overflowY: 'auto'
                                                }"
                                                rows="1"
                                                label="Número do Título"
                                                hint="Informe o Número do Título"
                                                placeholder="Ex.:477977"
                                                class="mt-5"
                                                v-model="
                                                    filtros.ds_nosso_numero
                                                "
                                            ></v-textarea>
                                        </v-col>
                                        <v-col
                                            cols="4"
                                            xs="2"
                                            sm="2"
                                            md="3"
                                            lg="3"
                                            class="d-flex flex-row align-center"
                                        >
                                            <v-text-field
                                                solo
                                                small
                                                class="mt-5"
                                                label="CPF/CNPJ"
                                                hint="Informe o Número do CPF/CNPJ"
                                                placeholder="Ex.:000.000.000-00"
                                                v-maska="[
                                                    '###.###.###-##',
                                                    '##.###.###/####-##'
                                                ]"
                                                v-model="
                                                    filtros.nu_identificacao_devedor
                                                "
                                                clearable
                                                data-cy="filtroCpfCnpj"
                                            >
                                            </v-text-field>
                                        </v-col>

                                        <v-col
                                            cols="5"
                                            xs="2"
                                            sm="2"
                                            md="3"
                                            lg="3"
                                            class="d-flex flex-row"
                                        >
                                            <v-text-field
                                                solo
                                                small
                                                class="mt-5"
                                                label="Nome"
                                                hint="Informe o nome para efetuar a busca"
                                                placeholder="Ex.: José"
                                                v-model="filtros.nm_devedor"
                                                clearable
                                                data-cy="nomeDevedor"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col
                                            cols="4"
                                            xs="2"
                                            sm="2"
                                            md="3"
                                            lg="3"
                                            class="d-flex flex-row"
                                        >
                                            <v-autocomplete
                                                solo
                                                small
                                                class="mt-5"
                                                v-model="filtros.cd_status"
                                                :items="listaSituacoes"
                                                label="Status"
                                                item-text="nm_status"
                                                item-value="cd_status"
                                                clearable
                                                hint="Selecione o Status"
                                                persistent-hint
                                                data-cy="statusTitulo"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        align="center"
                                        class="mx-3 mt-1 mb-0"
                                    >
                                        <v-col
                                            cols="5"
                                            xs="2"
                                            sm="2"
                                            md="3"
                                            lg="3"
                                            class="d-flex flex-row"
                                        >
                                            <v-menu
                                                v-model="menuDataCadastroRange"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template
                                                    v-slot:activator="{ on }"
                                                >
                                                    <v-text-field
                                                        solo
                                                        small
                                                        v-model="
                                                            computedDateCadastroFormattedRange
                                                        "
                                                        label="Data de cadastro"
                                                        hint="Data de cadastro: inícial e final"
                                                        placeholder="Selecione no botão ao lado"
                                                        clearable
                                                        @change="
                                                            limparDataCadastro
                                                        "
                                                    ></v-text-field>
                                                    <v-btn
                                                        fab
                                                        x-small
                                                        elevation="0"
                                                        class="grey--text mt-3 ml-2"
                                                    >
                                                        <v-icon
                                                            class=""
                                                            v-on="on"
                                                        >
                                                            mdi-calendar
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-date-picker
                                                    v-model="
                                                        filtros.dt_cadastro_range
                                                    "
                                                    range
                                                    color="primary"
                                                    locale="pt-br"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col
                                            cols="4"
                                            xs="2"
                                            sm="2"
                                            md="3"
                                            lg="3"
                                            class="d-flex flex-row"
                                        >
                                            <v-menu
                                                v-model="menuDataMovimentoRange"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template
                                                    v-slot:activator="{ on }"
                                                >
                                                    <v-text-field
                                                        solo
                                                        v-model="
                                                            computedDateMovimentoFormattedRange
                                                        "
                                                        label="Data de movimentação"
                                                        placeholder="Selecione no botão ao lado"
                                                        hint="Data Movimento: Inicial e Final"
                                                        clearable
                                                        @change="
                                                            limparDataMovimento
                                                        "
                                                    ></v-text-field>
                                                    <v-btn
                                                        fab
                                                        x-small
                                                        elevation="0"
                                                        class="grey--text mt-3 ml-2"
                                                        data-cy="dataMovimentoFilter"
                                                    >
                                                        <v-icon
                                                            class=""
                                                            v-on="on"
                                                        >
                                                            mdi-calendar
                                                        </v-icon>
                                                    </v-btn>
                                                </template>

                                                <v-date-picker
                                                    v-model="
                                                        filtros.dt_arquivo_movimento_range
                                                    "
                                                    range
                                                    color="primary"
                                                    locale="pt-br"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col
                                            cols="5"
                                            xs="2"
                                            sm="2"
                                            md="3"
                                            lg="3"
                                            class="pa-3 d-flex justify-start align-center"
                                        >
                                            <v-select
                                                solo
                                                small
                                                v-model="filtros.cd_uf_devedor"
                                                :items="listaUfs"
                                                label="UF"
                                                clearable
                                                :return-object="true"
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                            cols="4"
                                            xs="2"
                                            sm="2"
                                            md="3"
                                            lg="3"
                                            class="pa-3 d-flex justify-start align-center"
                                        >
                                            <v-select
                                                solo
                                                small
                                                v-model="filtros.cd_carteira"
                                                :items="listaCarteiras"
                                                label="Carteiras"
                                                clearable
                                                :return-object="true"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        align="center"
                                        class="mx-3 mt-0 mb-0"
                                    >
                                        <v-col
                                            v-if="!isUsuarioAgrupador"
                                            cols="5"
                                            xs="2"
                                            sm="2"
                                            md="3"
                                            lg="3"
                                            class="pa-3 d-flex justify-start align-center"
                                        >
                                            <v-select
                                                solo
                                                dense
                                                :disabled="
                                                    loadingAgrupador ||
                                                        usuarioAgrupador != null
                                                "
                                                :loading="loadingAgrupador"
                                                :items="agrupadores"
                                                v-model="filtros.ds_agrupador"
                                                label="Agrupador"
                                                item-text="nm_agrupador"
                                                item-value="ds_agrupador"
                                                data-cy="agrupador"
                                            />
                                            <v-tooltip bottom>
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs
                                                    }"
                                                >
                                                    <v-btn
                                                        fab
                                                        x-small
                                                        class="mb-5 ml-2"
                                                        elevation="0"
                                                    >
                                                        <v-icon
                                                            color="grey"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            mdi-information-outline
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span
                                                    >Selecione o agrupador que
                                                    deseja filtrar</span
                                                >
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mx-3 mt-0 mb-0">
                                        <v-col
                                            cols="7"
                                            xs="7"
                                            sm="7"
                                            md="7"
                                            lg="7"
                                            class="px-3 pt-0 pb-3"
                                            align="left"
                                        >
                                            <div
                                                class="font-weight-bold text-h6"
                                                style="color: #6c6c6c"
                                            >
                                                Data de atualização do Título
                                            </div>
                                            <div
                                                class="font-weight-normal"
                                                style="color: #6c6c6c"
                                            >
                                                Pesquise por títulos atualizados
                                                dentro do prazo (em dias)
                                                selecionado.
                                            </div>
                                        </v-col>
                                        <v-col
                                            cols="5"
                                            xs="5"
                                            sm="5"
                                            md="5"
                                            lg="5"
                                            align="center"
                                            class="d-flex flex-row justify-end align-end px-3 pt-0 pb-3"
                                        >
                                            <v-btn
                                                class="ma-1"
                                                color="primary"
                                                @click="
                                                    setDataMovimentoFiltro(1)
                                                "
                                            >
                                                Até 1D
                                            </v-btn>
                                            <v-btn
                                                class="ma-1"
                                                color="primary"
                                                @click="
                                                    setDataMovimentoFiltro(7)
                                                "
                                            >
                                                Até 7D
                                            </v-btn>
                                            <v-btn
                                                class="ma-1"
                                                color="primary"
                                                @click="
                                                    setDataMovimentoFiltro(30)
                                                "
                                            >
                                                Até 30D
                                            </v-btn>
                                            <v-btn
                                                class="my-1 mr-0 ml-1"
                                                color="primary"
                                                @click="
                                                    setDataMovimentoFiltro(90)
                                                "
                                            >
                                                Até 90D
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mx-3 mt-2 mb-0 pb-1">
                                        <v-col
                                            cols="7"
                                            xs="4"
                                            sm="4"
                                            md="6"
                                            lg="6"
                                            class="px-3 pt-0 pb-3"
                                            align="left"
                                        >
                                            <div
                                                class="font-weight-bold text-h6"
                                                style="color: #6c6c6c"
                                            >
                                                Intervalo de datas com base na
                                                atualização do Título
                                            </div>
                                            <div
                                                class="font-weight-normal"
                                                style="color: #6c6c6c"
                                            >
                                                Pesquise por títulos atualizados
                                                dentro do período selecionado
                                                (em dias) através do range ao
                                                lado.
                                            </div>
                                        </v-col>
                                        <v-col
                                            cols="5"
                                            xs="5"
                                            sm="8"
                                            md="6"
                                            lg="6"
                                            align="center"
                                            class="d-flex flex-row justify-end align-end px-3 pt-0 pb-3"
                                        >
                                            <template>
                                                <v-card
                                                    flat
                                                    color="transparent"
                                                >
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col
                                                                cols="5"
                                                                xs="5"
                                                                sm="5"
                                                                md="5"
                                                                lg="12"
                                                            >
                                                                <v-card
                                                                    flat
                                                                    color="transparent"
                                                                >
                                                                    <v-subheader
                                                                        >Títulos
                                                                        atualizados
                                                                        entre
                                                                        {{
                                                                            valueMulti[0]
                                                                        }}
                                                                        dias e
                                                                        {{
                                                                            valueMulti[1]
                                                                        }}
                                                                        dias</v-subheader
                                                                    >
                                                                    <v-card-text
                                                                        style="min-width: 400px"
                                                                    >
                                                                        <v-range-slider
                                                                            v-model="
                                                                                valueMulti
                                                                            "
                                                                            :ripple="
                                                                                false
                                                                            "
                                                                            min="0"
                                                                            @input="
                                                                                setDataMovimentoFiltroRange(
                                                                                    valueMulti
                                                                                )
                                                                            "
                                                                            max="730"
                                                                            thumb-label="always"
                                                                            :thumb-size="
                                                                                35
                                                                            "
                                                                        />
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </template>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mx-3 mt-2 mb-0 pb-1">
                                        <v-col
                                            cols="2"
                                            xs="2"
                                            sm="2"
                                            md="2"
                                            lg="2"
                                            class="pa-3 d-flex justify-start align-center"
                                        >
                                        </v-col>
                                        <v-col
                                            cols="3"
                                            xs="3"
                                            sm="3"
                                            md="3"
                                            lg="3"
                                            class="pa-3 d-flex justify-start align-center"
                                        >
                                            <v-switch
                                                v-model="irregulares"
                                                hide-details
                                                small
                                                class="mt-n1 d-flex justify-center"
                                                label="Não mostrar Irregulares"
                                            ></v-switch>
                                        </v-col>
                                        <v-col
                                            cols="4"
                                            xs="4"
                                            sm="4"
                                            md="4"
                                            lg="4"
                                            align="center"
                                            class="d-flex flex-row justify-space-around"
                                        >
                                        </v-col>
                                        <v-col
                                            cols="6"
                                            xs="6"
                                            sm="6"
                                            md="3"
                                            lg="3"
                                            align="center"
                                            class="d-flex flex-row"
                                        >
                                            <v-btn
                                                block
                                                class="d-flex justify-center"
                                                color="primary"
                                                :loading="loadingFiltros"
                                                @click="botaoFiltrar"
                                                data-cy="filtrarTitulos"
                                            >
                                                Filtrar
                                                <v-icon class="ml-2"
                                                    >search</v-icon
                                                >
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-data-table
                                    :headers="headers"
                                    :items="titulos"
                                    item-text="titulo"
                                    v-model="selecionados"
                                    :options.sync="options"
                                    :loading="loading"
                                    :single-select="singleSelect"
                                    item-key="id_titulo"
                                    show-select
                                    :footer-props="{
                                        itemsPerPageOptions: [
                                            50,
                                            1000,
                                            5000,
                                            -1
                                        ],
                                        itemsPerPageText: 'Itens por página:'
                                    }"
                                    :server-items-length="totalTitulos"
                                    loading-text="Pesquisando títulos..."
                                    no-data-text="Preencha os filtros e clique em filtrar..."
                                    no-results-text="Nenhum título encontrado..."
                                    class="elevation-1 pa-3"
                                >
                                    <template
                                        v-slot:[`item.id_titulo`]="{ item }"
                                    >
                                        <span
                                            v-if="item.ds_revisao != ''"
                                            title="Este título precisa ser revisado. "
                                            :style="{ color: 'red' }"
                                            >{{ item.id_titulo }}</span
                                        >
                                        <span
                                            v-else
                                            :style="{ color: 'black' }"
                                            >{{ item.id_titulo }}</span
                                        >
                                    </template>
                                    <template
                                        v-slot:[`item.ds_nosso_numero`]="{
                                            item
                                        }"
                                    >
                                        <!---<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ item.ds_numero_titulo }}</span>--->
                                        <span
                                            v-if="item.ds_revisao != ''"
                                            title="Este título precisa ser revisado. "
                                            :style="{ color: 'red' }"
                                            >{{ item.ds_nosso_numero }}</span
                                        >
                                        <span
                                            v-else
                                            :style="{ color: 'black' }"
                                            >{{ item.ds_nosso_numero }}</span
                                        >
                                    </template>
                                    <template
                                        v-slot:[`item.dt_cadastro`]="{ item }"
                                    >
                                        <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ item.dt_cadastro }}</span>-->
                                        <span
                                            v-if="item.ds_revisao != ''"
                                            title="Este título precisa ser revisado. "
                                            :style="{ color: 'red' }"
                                            >{{ item.dt_cadastro }}</span
                                        >
                                        <span
                                            v-else
                                            :style="{ color: 'black' }"
                                            >{{ item.dt_cadastro }}</span
                                        >
                                    </template>
                                    <template
                                        v-slot:[`item.dt_vencimento`]="{ item }"
                                    >
                                        <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ formatDataVencimento(item) }}</span>-->
                                        <span
                                            v-if="item.ds_revisao != ''"
                                            title="Este título precisa ser revisado. "
                                            :style="{ color: 'red' }"
                                            >{{
                                                formatDataVencimento(item)
                                            }}</span
                                        >
                                        <span
                                            v-else
                                            :style="{ color: 'black' }"
                                            >{{
                                                formatDataVencimento(item)
                                            }}</span
                                        >
                                    </template>
                                    <template
                                        v-slot:[`item.nm_devedor`]="{ item }"
                                    >
                                        <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ item.nm_devedor }}</span>-->
                                        <span
                                            v-if="item.ds_revisao != ''"
                                            title="Este título precisa ser revisado. "
                                            :style="{ color: 'red' }"
                                            >{{ item.nm_devedor }}</span
                                        >
                                        <span
                                            v-else
                                            :style="{ color: 'black' }"
                                            >{{ item.nm_devedor }}</span
                                        >
                                    </template>
                                    <template
                                        v-slot:[`item.vl_saldo_protesto`]="{
                                            item
                                        }"
                                    >
                                        <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ item.vl_saldo_protesto }}</span>-->
                                        <span
                                            v-if="item.ds_revisao != ''"
                                            title="Este título precisa ser revisado. "
                                            :style="{ color: 'red' }"
                                            >{{ item.vl_saldo_protesto }}</span
                                        >
                                        <span
                                            v-else
                                            :style="{ color: 'black' }"
                                            >{{ item.vl_saldo_protesto }}</span
                                        >
                                    </template>
                                    <template
                                        v-slot:[`item.cd_status`]="{ item }"
                                    >
                                        <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ getDescSituacao(item) }}</span>-->
                                        <span :style="{ color: 'black' }">{{
                                            getDescSituacao(item)
                                        }}</span>
                                    </template>
                                    <template
                                        v-slot:[`item.dt_atualizacao`]="{
                                            item
                                        }"
                                    >
                                        <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ item.dt_atualizacao }}</span>-->
                                        <span
                                            v-if="item.ds_revisao != ''"
                                            title="Este título precisa ser revisado. "
                                            :style="{ color: 'red' }"
                                            >{{ item.dt_atualizacao }}</span
                                        >
                                        <span
                                            v-else
                                            :style="{ color: 'black' }"
                                            >{{ item.dt_atualizacao }}</span
                                        >
                                    </template>
                                    <template
                                        v-slot:[`item.cd_uf_devedor`]="{ item }"
                                    >
                                        <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ item.cd_uf_devedor }}</span>-->
                                        <span
                                            v-if="item.ds_revisao != ''"
                                            title="Este título precisa ser revisado. "
                                            :style="{ color: 'red' }"
                                            >{{ item.cd_uf_devedor }}</span
                                        >
                                        <span
                                            v-else
                                            :style="{ color: 'black' }"
                                            >{{ item.cd_uf_devedor }}</span
                                        >
                                    </template>
                                    <template v-slot:[`item.oferta`]="{ item }">
                                        <v-icon
                                            :style="{
                                                color:
                                                    item.oferta > 0
                                                        ? 'green'
                                                        : 'red'
                                            }"
                                        >
                                            {{ getBolean(item.oferta) }}</v-icon
                                        >
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-container>
            <v-card-actions
                style="display: flex; justify-content: end; padding: 8px 48px 8px 16px"
            >
                <v-btn
                    class="ml-5"
                    :loading="loading"
                    :disabled="verificaRules"
                    color="green white--text"
                    v-if="newCampaign"
                    @click="ativaModal"
                >
                    <span
                        ><v-icon small cass="white--text">mdi-check</v-icon
                        >Ativar Campanha</span
                    >
                </v-btn>
                <v-btn
                    class="ml-5"
                    color="gray"
                    width="120"
                    :loading="loading"
                    @click="fecharModal"
                >
                    Fechar
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog
            v-if="modalAvisoConfirmacao"
            v-model="modalAvisoConfirmacao"
            max-width="800px"
            scrollable
            color="background"
            transition="dialog-bottom-transition"
        >
            <!-- Informações sobre a Campanha -->
            <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar flat dark color="primary">
                        <v-btn icon dark @click="modalAvisoConfirmacao = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Condições de Criação da Campanha
                        </v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-divider />
                <v-card-text class="px-8 py-4">
                    <v-row class="d-flex text-center">
                        <v-col lg="12">
                            <v-card>
                                <v-card>
                                    <v-icon
                                        color="error"
                                        size="200%"
                                        class="mt-5"
                                    >
                                        mdi-information
                                    </v-icon>
                                    <v-card-text class="title error--text">
                                        Essa campanha possui
                                        {{ titulosFailQtd() }} título(s) com
                                        dados incompletos
                                    </v-card-text>
                                    <v-card-text class="error--text">
                                        Os títulos que se encontram nessa lista
                                        serão removidos da campanha. Para que
                                        possa utilizar um título para envio de
                                        mensagens ele precisa ter informações de
                                        contato cadastradas
                                    </v-card-text>
                                </v-card>
                                <v-card-text class="px-8 py-4">
                                    <v-data-table
                                        :headers="titulosFailHeader"
                                        :items="titulosFail"
                                    >
                                        <template
                                            v-slot:[`item.value`]="{ item }"
                                        >
                                            <span
                                                v-if="item.value === ''"
                                                class="error--text"
                                                ><v-icon
                                                    size="small"
                                                    class="mx-2"
                                                    >mdi-close</v-icon
                                                >Não existe</span
                                            >
                                            <span
                                                v-else
                                                :style="{ color: 'black' }"
                                                >{{ item.value }}</span
                                            >
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex text-center">
                        <v-col lg="12">
                            <v-card>
                                <v-card>
                                    <v-icon
                                        color="success"
                                        size="200%"
                                        class="mt-5"
                                    >
                                        mdi-information
                                    </v-icon>
                                    <v-card-text class="title success--text">
                                        Essa campanha possui
                                        {{ titulosSelecionadosQtd() }} título(s)
                                        prontos para envio.
                                    </v-card-text>
                                    <v-card-text class="success--text">
                                        Os títulos que se encontram nessa lista
                                        foram verificados e aprovados para envio
                                        da campanha de e-mail.
                                    </v-card-text>
                                </v-card>
                                <v-card-text class="px-8 py-4">
                                    <v-data-table
                                        :headers="titulosFailHeader"
                                        :items="selecionadosFinal"
                                    >
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex text-center">
                        <v-col lg="12">
                            <v-card>
                                <v-icon
                                    color="primary"
                                    size="200%"
                                    class="mt-5"
                                >
                                    mdi-information
                                </v-icon>
                                <v-card-text class="title primary--text">
                                    Tem certeza dos dados informados?
                                </v-card-text>
                                <v-card-text class="title">
                                    Ao ativar a campanha, ela estará ativa e
                                    disponível para envio a qualquer momento.
                                </v-card-text>
                                <v-card-text>
                                    Importante: O envio de mensagens de email
                                    pode gerar custos adicionais. Confirme a
                                    criação dessa campanha caso tenha total
                                    ciência de que a Recuperi poderá entrar em
                                    contato com todos os devedores selecionados,
                                    através do contato informado.
                                </v-card-text>
                                <v-card-text>
                                    Em caso de dúvidas, entre em contato com
                                    nosso suporte através do e-mail:
                                    suporte@recuperi.com.br
                                </v-card-text>
                                <v-card
                                    class="d-flex justify-center"
                                    style="display:flex; align-content: center; justify-content: center; text-align: center"
                                >
                                    <v-checkbox
                                        v-model="liConcordo"
                                        :label="
                                            `Li e concordo com as condições acima.`
                                        "
                                    ></v-checkbox>
                                </v-card>
                            </v-card>
                        </v-col>
                        <v-col lg="2"></v-col>
                    </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions
                    style="display: flex; justify-content: end; padding: 8px 48px 8px 16px"
                >
                    <v-btn
                        class="ml-5"
                        color="success white--text"
                        width="120"
                        :loading="loading"
                        :disabled="!liConcordo"
                        @click="validarSalvarCampanha"
                    >
                        Confirmar
                    </v-btn>
                    <v-btn
                        class="ml-5"
                        color="grey white--text"
                        width="120"
                        :loading="loading"
                        @click="modalAvisoConfirmacao = false"
                    >
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
            <!-- fim das informações sobre a campanha --------------------- -->
        </v-dialog>
        <v-dialog
            v-model="dialogVisible"
            v-if="dialogVisible"
            max-width="800"
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card-title>Resultado da criação de Campanhas</v-card-title>
                <v-card-text>
                    <div
                        v-show="responseData.Msg"
                        v-for="(messageItem, index) in responseData.Msg"
                        :key="index"
                    >
                        <v-card class="ma-3 pa-3" color="grey lighten-3">
                            <v-card-title class="grey white--text"
                                ><v-icon class="mx-3 white--text"
                                    >mdi-information-outline</v-icon
                                >Mensagem</v-card-title
                            >
                            <v-card-text class="ma-3 pa-3">
                                <b>{{ messageItem }}</b>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div
                        v-show="responseData.Success"
                        v-for="(successItem, index) in responseData.Success"
                        :key="index"
                    >
                        <v-card class="ma-3 pa-3" color="grey lighten-3">
                            <v-card-title class="success white--text"
                                ><v-icon class="mx-3 white--text"
                                    >mdi-check-decagram</v-icon
                                >Resultado de campanhas com
                                sucesso:</v-card-title
                            >
                            <div
                                v-for="(campaignItem,
                                index) in successItem.email_campaign"
                                :key="index"
                            >
                                <v-card>
                                    <v-card-title
                                        >Detalhes da Campanha:</v-card-title
                                    >
                                    <v-card-text>
                                        <b>ID da campanha de e-mail:</b>
                                        {{ campaignItem.id }}
                                    </v-card-text>
                                    <v-card-text>
                                        <b>Environment:</b>
                                        {{ campaignItem.environment }}
                                    </v-card-text>
                                    <v-card-text>
                                        <b>Carimbo de Tempo:</b>
                                        {{ campaignItem.time_stamp }}
                                    </v-card-text>
                                    <v-card-text>
                                        <b>Nome da Campanha:</b>
                                        {{ campaignItem.campaign_name }}
                                    </v-card-text>
                                    <v-card-text>
                                        <b>Status:</b>
                                        {{ campaignItem.is_active }}
                                    </v-card-text>
                                    <v-card-text>
                                        <b>Assunto do E-mail:</b>
                                        {{ campaignItem.email_subject }}
                                    </v-card-text>
                                    <v-card-text>
                                        <b>URL de destino:</b>
                                        {{ campaignItem.destination_url }}
                                    </v-card-text>
                                    <v-card-text>
                                        <b
                                            >Referência de contato nas
                                            mensagens:</b
                                        >
                                        {{ campaignItem.destination_name }}
                                    </v-card-text>
                                </v-card>
                            </div>
                            <div
                                v-for="(contactItem,
                                index) in successItem.contacts"
                                :key="index"
                            >
                                <v-card>
                                    <v-card-title>
                                        Contatos:
                                    </v-card-title>
                                    <v-col>
                                        <v-card-text>
                                            <b>Nome:</b>
                                            {{ contactItem.name }}
                                        </v-card-text>
                                        <v-card-text>
                                            <b>Contato:</b>
                                            {{ contactItem.value }}
                                        </v-card-text>
                                        <v-card-text>
                                            <b>Tipo:</b>
                                            {{ contactItem.type }}
                                        </v-card-text>
                                    </v-col>
                                </v-card>
                            </div>
                        </v-card>
                    </div>
                    <div
                        v-show="responseData.Error"
                        v-for="(errorItem, index) in responseData.Error"
                        :key="index"
                    >
                        <v-card class="ma-3 pa-3" color="grey lighten-3">
                            <v-card-title class="error white--text"
                                ><v-icon class="mx-3 white--text"
                                    >mdi-close</v-icon
                                >Resultado de campanhas com erro:</v-card-title
                            >
                            <div
                                v-for="(campaignItem,
                                index) in errorItem.email_campaign"
                                :key="index"
                            >
                                <v-card>
                                    <v-card-title
                                        >Detalhes da Campanha:</v-card-title
                                    >
                                    <v-card-text>
                                        <b>ID da campanha de e-mail:</b>
                                        {{ campaignItem.id }}
                                    </v-card-text>
                                    <v-card-text>
                                        <b>Environment:</b>
                                        {{ campaignItem.environment }}
                                    </v-card-text>
                                    <v-card-text>
                                        <b>Carimbo de Tempo:</b>
                                        {{ campaignItem.time_stamp }}
                                    </v-card-text>
                                    <v-card-text>
                                        <b>Nome da Campanha:</b>
                                        {{ campaignItem.campaign_name }}
                                    </v-card-text>
                                    <v-card-text>
                                        <b>Status:</b>
                                        {{ campaignItem.is_active }}
                                    </v-card-text>
                                    <v-card-text>
                                        <b>Assunto do E-mail:</b>
                                        {{ campaignItem.email_subject }}
                                    </v-card-text>
                                    <v-card-text>
                                        <b>URL de destino:</b>
                                        {{ campaignItem.destination_url }}
                                    </v-card-text>
                                    <v-card-text>
                                        <b
                                            >Referência de contato nas
                                            mensagens:</b
                                        >
                                        {{ campaignItem.destination_name }}
                                    </v-card-text>
                                </v-card>
                            </div>
                            <div
                                v-for="(contactItem,
                                index) in errorItem.contacts"
                                :key="index"
                            >
                                <v-card>
                                    <v-card-title>
                                        Contatos:
                                    </v-card-title>
                                    <v-col>
                                        <v-card-text>
                                            <b>Nome:</b>
                                            {{ contactItem.name }}
                                        </v-card-text>
                                        <v-card-text>
                                            <b>Contato:</b>
                                            {{ contactItem.value }}
                                        </v-card-text>
                                        <v-card-text>
                                            <b>Tipo:</b>
                                            {{ contactItem.type }}
                                        </v-card-text>
                                    </v-col>
                                </v-card>
                            </div>
                        </v-card>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="closeDialog">Fechar Resultados</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as Formatter from '@/helpers/Formatter';
import Vue from 'vue';
import NotificaEmailService from '@/services/NotificaEmailService';
import {
    getOnlyNumbers,
    getDataHoraString,
    listarSituacoesTitulo,
    getDataAtualDatePicker,
    getDataDatePicker,
    getDataHoraSubtraida,
    listarAgrupadores
} from '@/helpers/Utilitarios';
import TituloService from '@/services/TituloService';
import moment from 'moment';

export default {
    name: 'DetalheCampanhaEmail',
    props: {
        campanha: {
            type: Object,
            required: true
        },
        validarForm: {
            type: Boolean,
            required: false
        },
        titulosCampanha: {
            type: Object,
            required: false
        }
    },
    components: {},
    data: () => ({
        colorSnackbar: null,
        mensagemSnackbar: null,
        valueMulti: [0, 365],
        liConcordo: false,
        modalAvisoConfirmacao: false,
        mensagemExibicao: null,
        resultsTitulo: null,
        newCampaign: false,
        snackbar: false,
        snackbarColor: 'green white--text',
        mensagem: null,
        mensagemAviso: null,
        finalizado: true,
        options: {
            page: 1,
            itemsPerPage: 50
        },
        irregulares: false,
        loadingFiltros: false,
        loadingContatos: false,
        headersResults: [
            {
                text: 'Nome',
                align: 'left',
                sortable: true,
                value: 'name'
            },
            {
                text: 'E-mail',
                sortable: true,
                value: 'value'
            },
            {
                text: 'Tipo',
                sortable: true,
                value: 'type'
            },
            {
                text: 'Enviado',
                sortable: true,
                value: 'sent'
            },
            {
                text: 'Processamento',
                sortable: true,
                value: 'processing_status'
            },
            {
                text: 'Criado em',
                sortable: true,
                value: 'created_at'
            },
            {
                text: 'Atualizado em',
                sortable: true,
                value: 'updated_at'
            },
            {
                text: 'Bounce',
                sortable: true,
                value: 'bounce'
            }
        ],
        titulosResult: [],
        headers: [
            {
                text: 'Código do título',
                sortable: true,
                value: 'ds_nosso_numero'
            },
            {
                text: 'Data de envio',
                align: 'left',
                sortable: true,
                value: 'dt_cadastro'
            },
            {
                text: 'CPF/CNPJ',
                sortable: true,
                value: 'nu_identificacao_devedor'
            },
            { text: 'Nome', sortable: true, value: 'nm_devedor' },
            {
                text: 'Valor R$',
                sortable: true,
                value: 'vl_saldo_protesto'
            },
            { text: 'Status', sortable: true, value: 'cd_status' },
            {
                text: 'Data de atualização',
                align: 'left',
                sortable: true,
                value: 'dt_atualizacao'
            },
            { text: 'UF', sortable: true, value: 'cd_uf_devedor' },
            { text: 'Email', sortable: true, value: 'ds_email_devedor' },
            // { text: 'Renegociação', sortable: true, value: 'oferta' },
            { text: ' ', sortable: true, value: 'action' }
        ],
        titulos: [],
        loading: false,
        totalTitulos: 0,
        singleSelect: false,
        selecionados: [],
        selecionadosFinal: [],
        filtros: {
            ds_revisao: null,
            irregulares: null,
            cd_status: null,
            nm_devedor: null,
            cd_carteira: 'Todos',
            nu_identificacao_devedor: null,
            ds_nosso_numero: null,
            ds_numero_titulo: null,
            ds_agrupador: null,
            apontamento_nu_protocolo: null,
            dt_vencimento_range: [
                //new Date(
                //  new Date().setFullYear(new Date().getFullYear() - 1)
                //)
                //  .toISOString()
                //.substr(0, 10),
                //new Date().toISOString().substr(0, 10)
            ],
            dt_cadastro_range: [
                //new Date(
                //  new Date().setFullYear(new Date().getFullYear() - 1)
                //)/
                //  .toISOString()
                //.substr(0, 10),
                //new Date().toISOString().substr(0, 10)
            ],
            dt_arquivo_movimento_range: [
                //new Date(
                //  new Date().setFullYear(new Date().getFullYear() - 1)
                //)
                //  .toISOString()
                //.substr(0, 10),
                //new Date().toISOString().substr(0, 10)
            ],
            dt_vencimento_range_fmt: null,
            dt_arquivo_movimento_range_fmt: null,
            dt_cadastro_range_fmt: null
        },
        listaUfs: [
            'AC',
            'AL',
            'AP',
            'AM',
            'BA',
            'CE',
            'DF',
            'ES',
            'GO',
            'MA',
            'MT',
            'MS',
            'MG',
            'PA',
            'PB',
            'PR',
            'PE',
            'PI',
            'RJ',
            'RN',
            'RS',
            'RO',
            'RR',
            'SC',
            'SP',
            'SE',
            'TO'
        ],
        menuDataMovimentoRange: false,
        menuDataCadastroRange: false,
        logo: require('../assets/recuperi-650x650.png'),
        fab: true,
        fav: true,
        menu: false,
        message: false,
        hints: true,
        phone: '5521997642382',
        text: '',
        apilink: '',
        requiredRules: [v => !!v || 'Campo Obrigatório'],
        valid: false,
        menu1: false,
        menu2: null,
        menu3: null,
        menu4: null,
        errorMessages: null,
        checkbox: null,
        enabled: null,
        example: null,
        title: null,
        html: null,
        send_date: null,
        status: [],
        items: [
            {
                message:
                    'Olá, Você permanece protestado! Entre em contato com a empresa XYZ para regularizar suas dívidas',
                status: '102 - Protestado'
            }
        ],
        diaInicialRange: null,
        diaFinalRange: null,
        loadingAgrupador: false,
        campanhaSelecionada: null,
        dialogVisible: false,
        responseData: {
            Msg: [],
            Success: [],
            Error: []
        },
        templateLoading: false,
        titulosFail: null,
        titulosFailHeader: [
            { text: 'Nome', sortable: true, value: 'name' },
            { text: 'E-mail', sortable: true, value: 'value' },
            { text: 'Tipo', sortable: true, value: 'type' }
        ]
    }),
    watch: {
        options: {
            handler() {
                if (this.totalTitulos > 0) {
                    this.aplicarFiltros();
                }
            },
            deep: true
        }
    },
    methods: {
        desabilitarCampanha() {
            let campanha = new NotificaEmailService(Vue.http, this.$store);
            let data = this.campanha;
            data.is_active = false;
            campanha.atualizaStatusCampanhaEmail(data).then(
                response => {
                    if (response.status == 200) {
                        this.exibirSnackbar(
                            'success',
                            'Atualizada com Sucesso'
                        );
                        this.responseData = response.body;
                        this.openDialog();
                    }
                    setTimeout(this.showMessage, 2000);
                },
                error => {
                    console.log(error);
                    this.responseData = error.body;
                    this.openDialog();
                    this.exibirSnackbar(
                        'error',
                        'Erro ao salvar Campanha, verifique os parametros informados'
                    );
                }
            );
        },
        exibirSnackbar(colorSnackbar, mensagemSnackbar) {
            this.snackbar = true;
            this.colorSnackbar = colorSnackbar;
            this.mensagemSnackbar = mensagemSnackbar;
        },
        openDialog() {
            this.dialogVisible = true;
        },
        closeDialog() {
            this.dialogVisible = false;
            this.fecharModal();
        },
        listarAgrupadores,
        async checarAgrupadores() {
            try {
                this.loadingAgrupador = true;
                await this.listarAgrupadores(this.$store, true);
            } catch (e) {
                console.log(e.data.mensagem);
                // TODO incluir chamada de snackbar quando REC-2805 for concluída
            } finally {
                this.loadingAgrupador = false;
            }
        },
        atualizaRange() {
            this.filtros.dt_arquivo_movimento_range = [
                this.diaInicialRange,
                this.diaFinalRange
            ];
        },
        subtractStartDays() {
            const today = new Date();
            const DaysAgoStart = new Date(
                today.getTime() - this.valueMulti[0] * 24 * 60 * 60 * 1000
            );
            this.diaInicialRange = DaysAgoStart;
            return moment(DaysAgoStart).format('DD/MM/YYYY');
        },
        subtractEndDays() {
            const today = new Date();
            const DaysAgoEnd = new Date(
                today.getTime() - this.valueMulti[1] * 24 * 60 * 60 * 1000
            );
            this.diaFinalRange = DaysAgoEnd;
            return moment(DaysAgoEnd).format('DD/MM/YYYY');
        },
        ativaModal() {
            if (this.selecionados.length == 0 && this.newCampaign) {
                this.exibirSnackbar(
                    'error',
                    'Não há Títulos Selecionados: Selecione ao menos 1 título para que a campanha possa ser iniciada!'
                );
            } else {
                if (!this.newCampaign) {
                    // this.validarSalvarCampanha();
                } else {
                    this.titulosSelecionados();
                    this.modalAvisoConfirmacao = true;
                }
            }
        },
        verificaJwt() {
            if (
                this.campanha.entity_name !== null &&
                this.campanha.entity_url !== null
            ) {
                this.campanha.entity_name = this.campanha.entity_name;
                this.campanha.entity_url = this.campanha.entity_url;
            } else {
                this.campanha.entity_name = this.$store.getters.NomeExibicaoApresentante;
                this.campanha.entity_url = this.$store.getters.linkContatoApresentante;
            }
        },
        verificaSituacaoCampanha() {
            if (!this.campanha.id) {
                this.newCampaign = true;
            } else {
                this.newCampaign = false;
                this.atualiza();
            }
        },
        formatDate(item) {
            let dataFormatada;
            if (item) {
                dataFormatada = moment(item).format('DD/MM/YYYY');
            } else {
                dataFormatada = '--';
            }
            return dataFormatada;
        },
        atualiza() {
            this.verificaContatosCampanha();
        },
        verificaContatosCampanha() {
            this.loadingContatos = true;
            let campanha = this.campanha.id;
            let emailService = new NotificaEmailService(Vue.http, this.$store);
            emailService.verificaContatosCampanha(campanha).then(
                response => {
                    this.titulosResult = response.body.results;
                    this.loadingContatos = false;
                    if (this.titulosResult.length === 0) {
                        this.exibirSnackbar(
                            'info',
                            'Não há contatos vinculados'
                        );
                    } else {
                        this.loading = false;
                        this.exibirSnackbar(
                            'success',
                            'Contatos verificados com sucesso'
                        );
                    }
                },
                error => {
                    this.loading = false;
                    this.mensagemAviso = error;
                    this.dialogLoading = false;
                    console.log(error);
                }
            );
        },
        fecharModal() {
            this.$emit('fecharModal');
        },
        salvarCampanha: async function() {
            let campanha = new NotificaEmailService(Vue.http, this.$store);
            if (this.campanha.id) {
                this.campanha.id = this.campanha.id;
            } else {
                this.campanha.apresentante = this.$store.getters.cdApresentante;
            }

            this.campanha.contacts = this.selecionadosFinal;
            let data = this.campanha;

            await campanha.salvarCampanhaEmail(data).then(
                response => {
                    if (response.status == 201) {
                        this.exibirSnackbar('success', 'Criado com Sucesso');
                        this.responseData = response.body;
                        this.openDialog();
                    } else if (response.status == 200) {
                        this.exibirSnackbar('success', 'Criado com Sucesso');
                        this.loading = false;
                        this.responseData = response.body;
                        this.openDialog();
                    }

                    setTimeout(this.showMessage, 2000);
                },
                error => {
                    console.log(error);
                    this.responseData = error.body;
                    this.openDialog();
                    this.exibirSnackbar(
                        'error',
                        'Erro ao salvar Campanha, verifique os parametros informados'
                    );
                }
            );
        },
        validarSalvarCampanha() {
            if (this.isInvalid) {
                this.validarForm = true;
            } else {
                this.modalAvisoConfirmacao = false;
                this.loading = true;
                this.salvarCampanha();
            }
        },
        titulosSelecionados() {
            const dadosWithEmail = this.selecionados.map(e => ({
                type: 'email',
                value: e.ds_email_devedor,
                name: e.nm_devedor
            }));

            const dadosWithNullEmail = this.selecionados
                .map(e => ({
                    type: 'email',
                    value: e.ds_email_devedor,
                    name: e.nm_devedor
                }))
                .filter(item => item.value.trim() === '');

            this.titulosFail = dadosWithNullEmail;

            const updatedSelecionadosFinal = dadosWithEmail.map(e => {
                const hasValidEmail = e.value.trim() !== '';
                return {
                    type: 'email',
                    value: e.value,
                    name: e.name,
                    disabled: !hasValidEmail
                };
            });

            this.selecionadosFinal = updatedSelecionadosFinal.filter(
                item => !item.disabled
            );
        },

        titulosSelecionadosQtd() {
            let dados = this.selecionadosFinal.length;
            return dados;
        },
        titulosFailQtd() {
            let dados = this.titulosFail.length;
            return dados;
        },
        getDescSituacao(titulo) {
            return Formatter.getDescSituacaoTitulo(
                titulo.cd_status,
                this.situacoesTitulo
            );
        },
        formatarDocumento() {
            var nu_identificacao_devedor = this.ultimoFiltro
                .nu_identificacao_devedor;
            if (nu_identificacao_devedor != null) {
                nu_identificacao_devedor = nu_identificacao_devedor.match(
                    /\d/g
                );
                nu_identificacao_devedor = nu_identificacao_devedor.join('');
                return nu_identificacao_devedor;
            } else {
                return null;
            }
        },
        buscarTitulos() {
            const page = this.options.page;
            const itemsPerPage = this.options.itemsPerPage;
            this.situacoesTitulo = this.$store.getters.situacoesTitulo;
            this.situacoesTitulo.push({
                cd_status: 99999,
                nm_status: 'EM REVISÃO'
            });
            if (this.ultimoFiltro == null) {
                this.ultimoFiltro = this.filtros;
            }
            this.ultimoFiltro.nu_identificacao_devedor = this.formatarDocumento();
            return new Promise((resolve, reject) => {
                this.loadingFiltros = true;
                this.loading = true;
                this.ultimoFiltro.colunas_full = 1;

                try {
                    let service = new TituloService(Vue.http, this.$store);

                    service
                        .filtrarTitulos(this.ultimoFiltro, page, itemsPerPage)
                        .then(
                            response => {
                                response.status;
                                response.statusText;
                                response.headers.get('Expires');
                                this.exportActive = false;
                                const {
                                    titulos,
                                    numero_linhas: total
                                } = response.body;

                                this.loadingFiltros = false;
                                this.loading = false;
                                resolve({
                                    titulos,
                                    total
                                });
                            },
                            error => {
                                this.loadingFiltros = false;
                                this.loading = false;
                                this.mensagem = error;
                                console.log(error);
                            }
                        );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        aplicarFiltros() {
            if (this.revisao == true) {
                this.filtros.ds_revisao = '1';
            } else {
                this.filtros.ds_revisao = '';
            }
            if (this.oferta == true) {
                this.filtros.oferta = '1';
            } else {
                this.filtros.oferta = '';
            }
            if (this.dadosXls == null) {
                this.filtros.ds_nosso_numero = this.filtros.ds_nosso_numero;
            } else {
                this.filtros.ds_nosso_numero = this.dadosXls.toString();
            }
            this.buscarTitulos().then(data => {
                this.titulos = data.titulos;
                this.totalTitulos = data.total;
                if (data.total === 0) {
                    this.mensagem = 'A Consulta não retornou nenhum titulo.';
                    this.numero_paginas = 1;
                } else {
                    this.mensagem = null;
                    this.numero_paginas =
                        data.total % this.registros_pagina == 0
                            ? data.total / this.registros_pagina
                            : parseInt(data.total / this.registros_pagina) + 1;
                }
                this.showPagination = this.numero_paginas > 1;
            });
        },
        limparDataCadastro() {
            this.filtros.dt_cadastro_range = [];
        },
        limparDataMovimento() {
            this.filtros.dt_arquivo_movimento_range = [];
        },
        dataCadastro() {
            this.filtros.dt_cadastro_range_fmt = [
                Formatter.formatData(this.filtros.dt_cadastro_range[0]),
                Formatter.formatData(this.filtros.dt_cadastro_range[1])
            ];
            if (this.filtros.dt_cadastro_range[1] == null) {
                return this.filtros.dt_cadastro_range_fmt[0];
            } else if (
                this.filtros.dt_cadastro_range_fmt[0] >
                this.filtros.dt_cadastro_range_fmt[1]
            ) {
                this.filtros.dt_cadastro_range_fmt = this.filtros.dt_cadastro_range_fmt.reverse();
                return this.filtros.dt_cadastro_range_fmt.join(' - ');
            } else {
                return this.filtros.dt_cadastro_range_fmt.join(' - ');
            }
        },
        dataMovimentacao() {
            this.filtros.dt_arquivo_movimento_range_fmt = [
                Formatter.formatData(
                    this.filtros.dt_arquivo_movimento_range[0]
                ),
                Formatter.formatData(this.filtros.dt_arquivo_movimento_range[1])
            ];
            if (this.filtros.dt_arquivo_movimento_range[1] == null) {
                return this.filtros.dt_arquivo_movimento_range_fmt[0];
            } else if (
                this.filtros.dt_arquivo_movimento_range[0] >
                this.filtros.dt_arquivo_movimento_range[1]
            ) {
                this.filtros.dt_arquivo_movimento_range_fmt = this.filtros.dt_arquivo_movimento_range_fmt.reverse();
                return this.filtros.dt_arquivo_movimento_range_fmt.join(' - ');
            } else {
                return this.filtros.dt_arquivo_movimento_range_fmt.join(' - ');
            }
        },
        botaoFiltrar() {
            this.filtrar = true;
            this.ultimoFiltro = this.filtros;
            this.aplicarFiltros();
        },
        getOnlyNumbers,
        getDataHoraString,
        listarSituacoesTitulo,
        getDataAtualDatePicker,
        getDataDatePicker,
        getDataHoraSubtraida,
        setDataMovimentoFiltro(intervaloDias) {
            const dataSubtraida = this.getDataHoraSubtraida(
                Date.now(),
                intervaloDias
            );

            const dataFinalDatePicker = this.getDataAtualDatePicker();
            const dataInicialDatePicker = this.getDataDatePicker(dataSubtraida);

            this.filtros.dt_arquivo_movimento_range = [
                dataFinalDatePicker,
                dataInicialDatePicker
            ];
        },
        setDataMovimentoFiltroRange(valueMulti) {
            const dataInicial = this.getDataHoraSubtraida(
                Date.now(),
                valueMulti[0]
            );

            const dataFinal = this.getDataHoraSubtraida(
                Date.now(),
                valueMulti[1]
            );

            const dataFinalDatePicker = this.getDataDatePicker(dataFinal);
            const dataInicialDatePicker = this.getDataDatePicker(dataInicial);

            this.filtros.dt_arquivo_movimento_range[1] = dataInicialDatePicker;
            this.filtros.dt_arquivo_movimento_range[0] = dataFinalDatePicker;
            this.filtros.dt_arquivo_movimento_de = this.filtros.dt_arquivo_movimento_range[0];
            this.filtros.dt_arquivo_movimento_ate = this.filtros.dt_arquivo_movimento_range[1];
            this.dataMovimentacao();
        },
        atualizaInput() {
            let message_body_html = this.campanhaSelecionada.html;
            let mail_template_id = this.campanhaSelecionada.id;
            this.campanha.email_template_id = mail_template_id;
            this.mensagemExibicao = message_body_html;
            // this.mensagemExibicao = this.mensagemExibicao.replace(
            //     '{{1}}',
            //     'João'
            // );
            // this.mensagemExibicao = this.mensagemExibicao.replace(
            //     '{{2}}',
            //     this.campanha.entity_name
            // );
            // this.mensagemExibicao = this.mensagemExibicao.replace(
            //     '{{3}}',
            //     this.campanha.entity_url
            // );
        },
        validarSalvar() {
            if (this.valid) {
                this.$emit('salvarEmail');
            } else {
                this.$refs.form.validate();
            }
        },
        listaTemplatesEmail() {
            this.loading = true;
            this.templateLoading = true;
            return new Promise((resolve, reject) => {
                try {
                    let emailService = new NotificaEmailService(
                        Vue.http,
                        this.$store
                    );
                    emailService.listarTemplates().then(
                        response => {
                            if (response.status == 200) {
                                this.status = response.body.results;
                                this.loading = false;
                                this.templateLoading = false;
                                if (!response.body.results) {
                                    this.exibirSnackbar(
                                        'error',
                                        'Não há templates ativos para o apresentante'
                                    );
                                }
                            }
                        },
                        error => {
                            console.log(error);
                        }
                    );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        checkChar() {
            if (this.campanha.message && this.campanha.message <= 100) {
                return true;
            } else {
                return false;
            }
        },
        tags(value) {
            this.campanha.message = `${this.campanha.message} {${value}}`;
        },
        formatSendDate(date) {
            if (!date) return null;
            if (date.toString().includes('-')) {
                let newDate = date.split('T')[0];
                const [year, month, day] = newDate.split('-');
                date = `${day}/${month}/${year}`;
                this.send_date = newDate;
            }
            if (this.send_date) {
                this.campanha.send_date = moment(this.send_date).format(
                    'YYYY-MM-DD HH:mm:ss'
                );
            }
            return date;
        },
        cartaoCheck() {
            this.errorMessages =
                this.campanha.cartao &&
                !this.computedJurosCartaoFormated &&
                !this.campanha.cartao
                    ? `Hey! I'm required`
                    : '';
        },
        formatDinheiroString(valor) {
            return Formatter.toString(valor);
        },
        formatPorcentagem(valor) {
            return Formatter.toString(valor);
        },
        descontoCheck() {
            if (
                this.campanha.desconto_porcentagem != null &&
                this.campanha.desconto_porcentagem != ''
            ) {
                this.campanha.desconto = null;
                return true;
            } else {
                return false;
            }
        },
        descontoCheckPorcentagem() {
            if (
                this.campanha.desconto != null &&
                this.campanha.desconto != ''
            ) {
                this.campanha.desconto_porcentagem = null;
                return true;
            } else {
                return false;
            }
        },
        limparCartao() {
            if (this.campanha.cartao === false) {
                this.campanha.juros_cartao = null;
            }
            if (this.campanha.cartao === false) {
                this.campanha.parcela_cartao = null;
            }
        },
        limparBoleto() {
            if (this.campanha.boleto === false) {
                this.campanha.juros_boleto = null;
            }
            if (this.campanha.boleto === false) {
                this.campanha.parcela_boleto = null;
            }
        },
        limparDatas() {
            if (this.campanha.dataCheck === true) {
                this.campanha.send_date = null;
            }
        },
        keyupListener(e) {
            if (!e) e = window.event;
            const keyCode = e.code || e.key;

            if (keyCode === 'Escape') {
                this.fecharModal();
            } else if (keyCode === 'Enter' || keyCode === 'NumpadEnter') {
                this.validarSalvar();
            }
        }
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.keyupListener);
    },
    async mounted() {
        this.loading = true;
        document.addEventListener('keyup', this.keyupListener);
        this.listaTemplatesEmail();
        this.verificaSituacaoCampanha();
        this.verificaJwt();

        if (this.usuarioAgrupador) {
            this.filtros.ds_agrupador = this.usuarioAgrupador;
        }

        this.loading = false;

        await this.checarAgrupadores();
    },
    computed: {
        verificaRules() {
            let value = true;
            let campanha = this.campanha;
            if (
                campanha.campaign_name &&
                campanha.destination_name &&
                campanha.destination_url &&
                campanha.email_template_id
            ) {
                return false;
            }
            return value;
        },
        selectedWithEmail() {
            return this.selecionadosFinal.filter(
                item => item.type === 'email' && item.value.trim() !== ''
            );
        },
        selectedWithoutEmail() {
            return this.selecionadosFinal.filter(
                item => item.type === 'email' && item.value.trim() === ''
            );
        },
        listaSituacoes() {
            return this.$store.getters.situacoesTitulo;
        },
        listaCarteiras() {
            return this.$store.getters.Carteiras;
        },
        computedDateMovimentoFormattedRange: {
            get: function() {
                return this.dataMovimentacao();
            },
            set: function(value) {
                this.menuDataMovimentoRange = value;
            }
        },
        computedDateCadastroFormattedRange: {
            get: function() {
                return this.dataCadastro();
            },
            set: function(value) {
                this.menuDataCadastroRange = value;
            }
        },
        computedDateFormattedInicio: {
            get: function() {
                if (this.campanha.send_date && !this.send_date) {
                    return this.formatSendDate(this.campanha.send_date);
                }
                return this.formatSendDate(this.send_date);
            },
            set: function(valor) {
                this.campanha.send_date = valor;
            }
        },
        computedMessageFormated: {
            get: function() {
                return this.startMessage();
            },
            set: function(valor) {
                this.campanha.message = valor;
                this.message = `Olá ${this.getNomeCliente}, ${valor}`;
            }
        },
        computedDescontoCheckPorcentagem() {
            return this.descontoCheckPorcentagem();
        },
        getNomeCliente() {
            return 'Cliente Exemplo';
        },
        superUsuario() {
            return this.$store.getters.getSuperUser === 1;
        },
        usuarioAgrupador() {
            return this.$store.getters.agrupadorUsuario;
        },
        agrupadores() {
            return this.$store.getters.listaAgrupadoresFiltro;
        },
        isUsuarioAgrupador() {
            return this.$store.getters.isUsuarioAgrupador;
        }
    }
};
</script>

<style scoped>
.my-input.v-input .v-input__slot {
    border-radius: 100px;
}
.chat-background {
    background-color: #ece5dd !important;
}
.chat-header {
    background-color: #075e54 !important;
}
.chip-chat {
    margin: 20px;
    padding: 10px;
    border-radius: 20px;
    background-color: #dcf8c6 !important;
}
</style>
