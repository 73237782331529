import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "margin-dialog-body" } },
    [
      _c(
        VCard,
        { staticClass: "mb-3" },
        [
          _c(
            VContainer,
            { attrs: { justify: "center" } },
            [
              _c(
                VRow,
                { attrs: { align: "center" } },
                [
                  _c(VCol, { attrs: { sm: "2" } }, [
                    _c("p", { staticClass: "font-weight-bold ml-3" }, [
                      _vm._v(
                        " Fazer Upload de Arquivo Excel com os Telefones. "
                      )
                    ])
                  ]),
                  _c(
                    VCol,
                    { attrs: { sm: "6" } },
                    [
                      _c(VFileInput, {
                        ref: "myFileInput",
                        staticClass: "mb-n7",
                        attrs: {
                          accept:
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx, .xlsm",
                          label: "Selecione o arquivo (.xls/.xlsx/ .xlsm)",
                          "show-size": "",
                          loading: _vm.loadingUpload,
                          disabled: _vm.desabilitaBoxEnvio,
                          chips: "",
                          outlined: "",
                          id: "fileInput",
                          name: "file"
                        },
                        on: { change: _vm.uploadFileReference }
                      })
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { attrs: { sm: "4", align: "center" } },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: {
                            color: "primary white--text",
                            large: "",
                            loading: _vm.loadingUpload,
                            disabled: _vm.uploadOk
                          },
                          on: { click: _vm.upload }
                        },
                        [
                          _vm._v(" Enviar "),
                          _c(VIcon, { staticClass: "ml-2" }, [
                            _vm._v("cloud_upload")
                          ])
                        ],
                        1
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "mx-3",
                          attrs: {
                            color: "primary white--text",
                            large: "",
                            href: _vm.linkModelo
                          }
                        },
                        [
                          _vm._v(" template "),
                          _c(VIcon, { staticClass: "ml-2" }, [
                            _vm._v("cloud_download")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    {
                      attrs: {
                        cols: "6",
                        sm: "4",
                        md: "12",
                        "justify-center": ""
                      }
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "primary--text display-1 text-center" },
                        [_vm._v(" Status de Upload: ")]
                      )
                    ]
                  ),
                  _c(
                    VCol,
                    { attrs: { cols: "6", sm: "4", md: "4" } },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: {
                            block: "",
                            large: "",
                            outlined: "",
                            color: "primary"
                          }
                        },
                        [
                          _vm._v(
                            " A Enviar: " + _vm._s(_vm.customerCountRead) + " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { attrs: { cols: "6", sm: "4", md: "4" } },
                    [
                      _c(
                        VBtn,
                        { attrs: { block: "", large: "", color: "success" } },
                        [_vm._v(" Enviados:" + _vm._s(_vm.customerCount) + " ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { attrs: { cols: "6", sm: "4", md: "4" } },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: {
                            block: "",
                            large: "",
                            outlined: "",
                            color: "error"
                          }
                        },
                        [
                          _vm._v(
                            " Erro: " + _vm._s(_vm.customerCountError) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        VCol,
        { attrs: { cols: "6", sm: "4", md: "12", "justify-center": "" } },
        [
          _c("p", { staticClass: "title-1 text-center" }, [
            _vm._v("Resultados:")
          ])
        ]
      ),
      _c(VDataTable, {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.contacts,
          options: _vm.options,
          loading: _vm.loading,
          "footer-props": {
            itemsPerPageOptions: [50, 100, 500]
          },
          "loading-text": "Atualizando...",
          "no-data-text": "Preencha os filtros e clique em filtrar...",
          "no-results-text": "Nenhum título encontrado..."
        },
        on: {
          "update:options": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.enviado",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    VIcon,
                    { style: { color: item.enviado != "" ? "green" : "red" } },
                    [_vm._v(_vm._s(_vm.getBolean(item.enviado)))]
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }